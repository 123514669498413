define(['app', 'siteObj'], (app, siteObj) => {
  const ProductPersonalisationMessageInput = () => {

    const component = {};

    const _selectors = {
      textInput: '[data-js-element="product-personalisation-input"]',
      resetButton: '[data-js-element="product-personalisation-reset-button"]',
      errorMessage: '[data-js-element="product-personalisation-error"]',
      warningMessage: '[data-js-element="product-personalisation-warning"]'

    };

    const _classes = {
      showInputMessaging: 'productPersonalisationMessageInput_messagingContainer--show'
    };

    const _publishChannels = {
      inputStateUpdated: 'productPersonalisation/inputStateUpdate',
      characterCountUpdated: 'productPersonalisation/characterCountUpdate',
    };

    const _subscribeChannels = {
      personalisationGetState: 'productPersonalisation/getInputState',
      personalisationResetInput: 'productPersonalisation/resetInput',
    };

    const _whitelist = {
      message: [],
      name: []
    };

    const _state = {
      isValid: true,
      displayable: true,
      data: "",
      remainingCharacters: 0,
      previousLength: 0,
    };

    const _config = {
      allowedCharacters: /[^0-9a-zA-ZÁÀÂÄÅÃÆÇÐÉÈÊËÍÌÎÏÑÓÒÔÖÕØŒÞÚÙÛÜÝŸàâäãåæçðéèêëíìîïıñóòôöõøœßþúùûüýÿĀĂĄĆČĎĐĒĖĘĚĞĢĪĮİĶŁĹĻĽŃŅŇŌŐŔŖŘŠŚŞȘŢȚŤŪŮŰŲŽŹŻăąćčďđēėęěğģīįķłĺļľńņňōőŕŗřšśşșţțťūůűųžźż,#?!&“”’' ]/g,
      allowedPunctuation: /[0-9,?!#&“”’']/g,
      invalidAmpersandPlacement: /&+([^\s])/g,
    };

    const _init = (element) => {
      component.element = element;
      component.elements = {
        textInput: element.querySelector(component.selectors.textInput),
        errorMessage: element.querySelector(component.selectors.errorMessage),
        warningMessage: element.querySelector(component.selectors.warningMessage),
        resetButton: element.querySelector(component.selectors.resetButton),
      };
      component.inputIdentifier = component.element.getAttribute("data-input-identifier");

      component.getWhitelist();

      component.bind();
      component.subscribe();

      component.state.remainingCharacters = component.elements.textInput.maxLength;
    };

    const _bind = () => {
      component.elements.textInput.addEventListener("blur", (e) => component.handleInput(e));
      component.elements.resetButton.addEventListener("click", () => component.handleReset());
      component.elements.textInput.addEventListener("input", (e) => component.handleCharacterCount(e.target.value));
    };

    const _subscribe = () => {
      app.subscribe(component.subscribeChannels.personalisationGetState, component.publishState);
      app.subscribe(component.subscribeChannels.personalisationResetInput, component.handleReset);
      app.subscribe(component.publishChannels.characterCountUpdated, (remainingCharacters) => component.updateCharacterCount(remainingCharacters));
    };

    const _publishState = () => {
      app.publish(component.publishChannels.inputStateUpdated, {
        inputIdentifier: component.inputIdentifier,
        state: component.state
      });
    };

    const _handleInput = (e) => {
      const inputValue = component.cleanseInput(e.target.value);
      e.target.value = inputValue;
      component.handleCharacterCount(inputValue);
      component.state.data = inputValue;
      component.validateInput();
    };

    const _handleReset = () => {
      component.state.data = "";
      component.elements.textInput.value = "";
      component.state.isValid = true;
      component.state.displayable = true;
      component.handleErrorStyling();
      component.publishState();
      component.elements.textInput.focus();
      component.handleCharacterCount("");
    };

    const _handleErrorStyling = () => {
      if (!component.state.isValid) {
        component.elements.errorMessage.classList.add(component.classes.showInputMessaging);
        component.elements.warningMessage.classList.remove(component.classes.showInputMessaging);

      } else if(!component.state.displayable) {
        component.elements.warningMessage.classList.add(component.classes.showInputMessaging);
        component.elements.errorMessage.classList.remove(component.classes.showInputMessaging);

      } else{
        component.elements.errorMessage.classList.remove(component.classes.showInputMessaging);
        component.elements.warningMessage.classList.remove(component.classes.showInputMessaging);
      }
    };

    const _validateAgainstBlackList = (text) => {
      return new Promise(function (res, rej) {
        const siteCode = siteObj.siteCode;
        return app.ajax.get({
          url: `/${siteCode}/basketMetadata/CocaColaPersonalisation/validate.listjson?value=${text}`,
          success: res,
          error: rej
        });
      });
    };

    const _getWhitelist = () => {
      app.ajax.get({
        url: '/basketMetadata/displayableValues.listjson',
        success: component.populateWhitelist,
        error: () => console.error('Could not get whitelist'),
      });
    };

    const _populateWhitelist = (data) => {
      const res = JSON.parse(data);
      const {displayablePhrases, displayableNames} = res;
      component.whitelist.message = displayablePhrases;
      component.whitelist.name = displayableNames;
    };

    const _validateAgainstWhitelist = (text) => {
      const res = component.whitelist[component.inputIdentifier].filter((word) => {
        return word.toLowerCase() === text.toLowerCase();
      });
      return res.length > 0;
    };

    const _validateInput = () => {
      if(component.state.data != "") {
        const formattedData = component.stripPunctuationAndNumbers(component.state.data);
        const inWhitelist = component.validateAgainstWhitelist(formattedData);
        if (inWhitelist) {
          component.state.displayable = true;
          component.state.isValid = true;
          component.publishState();
          component.handleErrorStyling();
        } else {
          component.state.displayable = false;
          component.validateAgainstBlackList(component.state.data).then(res => {
            component.state.isValid = (res == "true");
            component.publishState();
            component.handleErrorStyling();
          });
        }
      } else {
        component.state.displayable = true;
        component.state.isValid = true;
        component.publishState();
        component.handleErrorStyling();
      }
    };

    const _handleCharacterCount = (inputValue) => {
      if (component.state.previousLength === inputValue.length){
        return;
      } else if (component.state.data.length > inputValue.length) {
        component.state.remainingCharacters += component.state.previousLength - inputValue.length;
        app.publish(component.publishChannels.characterCountUpdated, component.state.remainingCharacters);
      } else {
        component.state.remainingCharacters -= inputValue.length - component.state.previousLength;
        app.publish(component.publishChannels.characterCountUpdated, component.state.remainingCharacters);
      }
      component.state.previousLength = inputValue.length;
    };

    const _updateCharacterCount = (remainingCharacters) => {
      component.state.remainingCharacters = remainingCharacters;
      component.elements.textInput.maxLength = remainingCharacters + component.elements.textInput.value.length;
    };

    const _stripPunctuationAndNumbers = (text) => {
      const strippedText = text.replace(component.config.allowedPunctuation, '');
      return strippedText.replace(/  +/g, ' ');
    };

    const _cleanseInput = (inputValue) => {
      if (inputValue.match(component.config.allowedCharacters)) {
        inputValue = inputValue.replace(component.config.allowedCharacters, '');
      }
      inputValue = inputValue.replace(component.config.invalidAmpersandPlacement, '$1');
      inputValue = inputValue.replace(/  +/g, ' ');
      inputValue = inputValue.trim();

      return inputValue;
    };

    component.init = _init;
    component.bind = _bind;
    component.state = _state;
    component.selectors = _selectors;
    component.classes = _classes;
    component.whitelist = _whitelist;
    component.publishChannels = _publishChannels;
    component.subscribeChannels = _subscribeChannels;
    component.config = _config;
    component.subscribe = _subscribe;
    component.publishState = _publishState;
    component.handleInput = _handleInput;
    component.handleReset = _handleReset;
    component.validateAgainstBlackList = _validateAgainstBlackList;
    component.handleErrorStyling = _handleErrorStyling;
    component.getWhitelist = _getWhitelist;
    component.populateWhitelist = _populateWhitelist;
    component.validateAgainstWhitelist = _validateAgainstWhitelist;
    component.validateInput = _validateInput;
    component.handleCharacterCount = _handleCharacterCount;
    component.updateCharacterCount = _updateCharacterCount;
    component.stripPunctuationAndNumbers = _stripPunctuationAndNumbers;
    component.cleanseInput = _cleanseInput;

    return component;
  };
  return ProductPersonalisationMessageInput;
});
